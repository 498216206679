import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { Checkbox } from 'components/Checkbox';
import { Input } from 'components/Input/Input';
import { InputType } from 'components/Input/InputProps';
import { RecaptchaInput } from 'components/RecaptchaInput';
import { SelectInput } from 'components/SelectInput';
import { configValue } from 'config/appConfigUtils';
import { CmsApiFormBuilderField } from 'modules/cms/api/types/blocks/CmsApiFormBuilderBlock';
import { trackEvent } from 'utils/analytics/track/trackEvent';

import { getCmsFormBuilderId } from './getCmsFormBuilderId';

export function CmsFormBuilderBlockField({
  field,
  action,
}: {
  field: CmsApiFormBuilderField;
  action: string;
}) {
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState(false);
  const [changed, setChanged] = useState(false);
  const id = getCmsFormBuilderId(field.name);
  const trackChange = useCallback(() => {
    if ('label' in field) {
      trackEvent('CMS Form Edited', {
        action,
        label: field.label,
      });
    }
  }, [action, field]);
  useEffect(() => {
    if (changed) {
      trackChange();
    }
  }, [changed, trackChange]);

  const setTextValue = (v: string) => {
    setValue(v);
    setChanged(true);
  };

  const setSelectValue = (v: string) => {
    setValue(v);
    trackChange();
  };

  const updateChecked = (c: boolean) => {
    setChecked(c);
    trackChange();
  };

  const setRecaptchaValue = (v: string) => {
    setValue(v);
    trackChange();
  };

  switch (field.type) {
    case 'SELECT':
      return (
        <SelectInput
          options={[
            {
              value: '',
              label: field.placeholder || getText('Select One'),
              disabled: true,
            },
            ...(field.options || []),
          ]}
          label={field.label}
          name={field.name}
          onChange={setSelectValue}
          value={value}
          required={field.required}
        />
      );

    case 'CHECKBOX':
      return (
        <Checkbox
          onChange={updateChecked}
          checked={checked}
          name={field.name}
          value={field.value}
          label={field.label}
          required={field.required}
          large
        />
      );

    case 'HIDDEN':
      return <input name={field.name} value={field.value} hidden />;

    case 'RECAPTCHA':
      return configValue('google', 'recaptcha', 'enabled') ? (
        <RecaptchaInput onChange={setRecaptchaValue} siteKey={field.siteKey} />
      ) : (
        <Checkbox
          onChange={updateChecked}
          checked={checked}
          value=""
          label="I'm not a robot"
          large
          name="gRecaptchaResponse"
          required
          data-qa-id={id}
        />
      );

    case 'TEXT':
    case 'EMAIL':
    default:
      return (
        <Input
          inputProps={{
            id,
            name: field.name,
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              setTextValue(e.target.value),
            onFocus: () => setChanged(false),
            placeholder: field.placeholder,
            qaId: id,
            required: field.required,
            type: field.type.toLowerCase() as InputType,
            value,
          }}
        />
      );
  }
}
