import queryString from 'query-string';
import { ComponentProps, useContext, useState } from 'react';
import styled from 'styled-components';

import { Box } from 'components/Box';
import { LegacyPrimaryButton as PrimaryButton } from 'components/Button/LegacyPrimaryButton';
import { FieldLabel } from 'components/Form/Field/FieldLabel';
import { BodyLarge } from 'components/Text/BodyLarge';
import { RichTextHeadline } from 'components/Text/RichTextHeadline';
import { TabContext } from 'containers/TabContext';
import {
  CmsApiFormBuilderBlockData,
  CmsApiFormBuilderField,
} from 'modules/cms/api/types/blocks/CmsApiFormBuilderBlock';
import { colors } from 'theme/theme';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { imageUrl } from 'utils/filestack';

import { CmsFormBuilderBlockContainer } from './CmsFormBuilderBlockContainer';
import { CmsFormBuilderBlockField } from './CmsFormBuilderBlockField';
import { getCmsFormBuilderId } from './getCmsFormBuilderId';

const StyledLabel = styled(FieldLabel)`
  color: white;
  margin-bottom: 6px;
`;

const SubmitButton = styled(
  ({ ...props }: ComponentProps<typeof PrimaryButton>) => (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PrimaryButton {...props} />
  ),
)`
  padding-left: 40px;
  padding-right: 40px;
  max-width: 440px;
  box-shadow: 0 0 0 1px white inset;
  background: none;
  color: white;

  &:focus,
  &:hover {
    box-shadow: 0 0 0 1px ${colors.brandGreen} inset;
    background: ${colors.brandGreen};
  }
`;

type Props = {
  data: CmsApiFormBuilderBlockData;
};

export function CmsFormBuilderBlock({
  data: {
    headline,
    submitButtonText,
    bgImage,
    action,
    newTab,
    fields,
    method,
    forwardUtmParams,
  },
}: Props) {
  const isTabbing = useContext(TabContext);
  const [submitted, setSubmitted] = useState(false);

  const injectUtmParams = () => {
    const utmParamContainer = document.getElementById('form-builder-utm');
    const queryParams = queryString.parse(window.location.search);
    const utmSource = queryParams.utm_source;
    if (utmSource) {
      const utmSourceInput = document.createElement('input');
      utmSourceInput.type = 'hidden';
      utmSourceInput.name = 'utm_source';
      utmSourceInput.defaultValue = Array.isArray(utmSource)
        ? utmSource[0] || ''
        : utmSource;
      if (utmParamContainer) {
        utmParamContainer.appendChild(utmSourceInput);
      }
    }
    const utmMedium = queryParams.utm_medium;
    if (utmMedium) {
      const utmMediumInput = document.createElement('input');
      utmMediumInput.type = 'hidden';
      utmMediumInput.name = 'utm_medium';
      utmMediumInput.defaultValue = Array.isArray(utmMedium)
        ? utmMedium[0] || ''
        : utmMedium;
      if (utmParamContainer) {
        utmParamContainer.appendChild(utmMediumInput);
      }
    }
    const utmCampaign = queryParams.utm_campaign;
    if (utmCampaign) {
      const utmCampaignInput = document.createElement('input');
      utmCampaignInput.type = 'hidden';
      utmCampaignInput.name = 'utm_campaign';
      utmCampaignInput.defaultValue = Array.isArray(utmCampaign)
        ? utmCampaign[0] || ''
        : utmCampaign;
      if (utmParamContainer) {
        utmParamContainer.appendChild(utmCampaignInput);
      }
    }
  };

  const onSubmit = () => {
    if (forwardUtmParams) {
      injectUtmParams();
    }
    setSubmitted(true);
    trackEvent('CMS Form Submitted', {
      action,
    });
  };

  const getInnerField = (field: CmsApiFormBuilderField) => (
    <>
      {/* {!['CHECKBOX', 'HIDDEN'].includes(field.type) && ( */}
      {field.type !== 'CHECKBOX' &&
        field.type !== 'HIDDEN' &&
        field.type !== 'RECAPTCHA' && (
          <StyledLabel htmlFor={getCmsFormBuilderId(field.name)}>
            {field.label}
          </StyledLabel>
        )}
      <CmsFormBuilderBlockField field={field} action={action} />
    </>
  );

  const getField = (index: number) => {
    // First in double text
    const currentField = fields[index];
    if (index + 1 < fields.length) {
      const nextField = fields[index + 1];
      if (
        currentField.type === 'TEXT' &&
        !currentField.doubleText &&
        nextField.type === 'TEXT' &&
        nextField.doubleText
      ) {
        return null;
      }
    }
    // Second in double text
    if (index > 0) {
      const previousField = fields[index - 1];
      if (
        currentField.type === 'TEXT' &&
        currentField.doubleText &&
        previousField.type === 'TEXT' &&
        !previousField.doubleText
      ) {
        return (
          <Box display="flex">
            <Box mr="24px" style={{ flexGrow: 1 }}>
              {getInnerField(fields[index - 1])}
            </Box>
            <Box style={{ flexGrow: 1 }}>{getInnerField(fields[index])}</Box>
          </Box>
        );
      }
    }
    return getInnerField(fields[index]);
  };

  return (
    <div>
      <Box
        p="80px 20px"
        width="auto !important"
        borderRadius={[0, 0, 0, 24]}
        background={
          bgImage
            ? `url("${imageUrl({
                data: bgImage,
                width: 2096,
                height: undefined,
                fit: 'max',
                insecureSVG: false,
              })}") center/cover`
            : colors.brandBlue
        }
      >
        {headline && (
          <Box maxWidth="960px" m="0 auto">
            <RichTextHeadline color="white" textAlignCenter>
              {headline}
            </RichTextHeadline>
          </Box>
        )}

        <Box position="relative">
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            display={submitted ? undefined : 'none'}
            width={1}
          >
            <BodyLarge color="white" textAlign="center">
              {getText('Thank you!')}
            </BodyLarge>
          </Box>

          <Box visibility={submitted && 'hidden'}>
            <CmsFormBuilderBlockContainer $isTabbing={isTabbing}>
              <form
                data-qa-id="cms-form-builder"
                action={action}
                target={newTab ? 'form-builder-submitted' : undefined}
                onSubmit={onSubmit}
                method={method}
              >
                <Box maxWidth="440px" m="40px auto 0">
                  {fields.map((field, index) => (
                    <>{getField(index)}</>
                  ))}
                  <div id="form-builder-utm" />
                  <Box classes={['flex', 'justify-center']} mt="40px">
                    <SubmitButton
                      $size="large"
                      type="submit"
                      data-qa-id="submit-button"
                    >
                      {submitButtonText}
                    </SubmitButton>
                  </Box>
                </Box>
              </form>
            </CmsFormBuilderBlockContainer>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

//
