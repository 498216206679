import styled from 'styled-components';

import { colors } from 'theme/theme';

export const CmsFormBuilderBlockContainer = styled.div<{
  $disabled?: boolean;
  $isTabbing?: boolean;
}>`
  label {
    color: ${colors.white};
  }
`;
